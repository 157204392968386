import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";
import ReactGA from "react-ga4";
import { titleImage } from "../../utils/formatters";
import { useRollbar } from "@rollbar/react";

// Selectors
import { getCurrentBooking } from "../../store/selectors/bookings";
// Dispatch actions
import { loadBooking, cancelBooking, confirmBooking } from "../../store/actions/bookings";

import { PageLayout, Container, Overlay, Icon, Heading3, Paragraph } from "../../components";
import Loading from "../../components/Loading";
import { CancelLink, LoadingContainer } from "../../components/Booking/Booking";
import CheckoutComponent from "../../components/Checkout/";
import Success from "./Success";

const CancelButton = styled.button`
  border: 0;
  padding: 0.4rem 0.5rem;
  background-color: ${props => props.theme.white};
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  color: ${props => props.theme.gray500};
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: ${props => props.theme.black};
  }
`;

const Message = styled.p`
  padding: 2rem;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

export const BookingOverlay = styled(Overlay)`
  top: 0;
  height: 100%;
  min-height: 100%;

  @media (${props => props.theme.tabletScreen}) {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 0;
    height: auto;
    padding: 1%;
  }

  @media (${props => props.theme.desktopScreen}) {
    top: auto;
    padding: 0;
  }
`;

export const BookingWrapper = styled(Container)`
  background-color: ${props => props.theme.white};
  min-height: 100%;
  text-align: left;
  position: relative;
  padding: 0.75rem 0.75rem 150px 0.75rem;
  box-shadow: ${props => props.theme.boxShadowLifted};

  @media (${props => props.theme.tabletScreen}) {
    border-radius: ${props => props.theme.borderRadius};
  }

  @media (${props => props.theme.desktopScreen}) {
    padding: 2rem;
    left: 0;
    width: 100%;
  }
`;

const Checkout = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rollbar = useRollbar();

  // Retrive booking id at first
  const { id } = useParams();

  // Get the booking if it is in state - on session
  const booking = useSelector(state => getCurrentBooking(state));

  // Propagate processing / confirming to prevent cancel
  const [confirming, setConfirming] = useState(false);
  const [processing, setProcessing] = useState(false);

  // Final success state
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    if (booking && booking.id === id) {
      if (booking.status === "paid") {
        setSuccess(true);
      }
      return;
    }
    dispatch(loadBooking(id)).catch(error => {
      setFailure(true);
      rollbar.error("Error loading booking", error, { bookingId: id });
    });
  }, [booking, id, dispatch, rollbar]);

  const onCancel = () => {
    // Don't allow a cancelation while processing
    if (processing) {
      return;
    }

    // If booking succeeded, close window and redirect to course
    if (success || booking.status === "paid") {
      dispatch(cancelBooking());
      return history.push(
        routeWithParams(routes.course, { slug: booking.provider.slug, id: booking.course.id }),
      );
    }

    // If ongoing, and not processing confirm cancel
    if (window.confirm(intl.formatMessage({ id: "components.Booking.cancelBookingConfirm" }))) {
      dispatch(cancelBooking(booking));
      history.push(
        routeWithParams(routes.course, { slug: booking.provider.slug, id: booking.course.id }),
      );
    }
  };

  // Finally call onSuccess if booking process succeeded
  // This function is supposed to be the callback for
  // a positive Stripe 'handleCardPayment'-request.
  // The paymentObject (PaymentIntent or Source) should then be used to confirm the booking.
  const onSuccess = async paymentObject => {
    if (!paymentObject) {
      return alert(intl.formatMessage({ id: "components.Booking.error.payment" }));
    }

    setConfirming(true);

    try {
      const confirmedBooking = await dispatch(confirmBooking(booking, paymentObject));

      if (confirmedBooking.id) {
        setSuccess(true);

        // Track GoogleAnalytics event if booking in state (checkout)
        if (booking) {
          ReactGA.event({
            category: "Booking",
            action: "checkout",
            label: (booking.event || {}).id.toString(),
            value: booking.costs && parseInt(booking.costs * 100),
          });
        }
      }
    } catch (error) {
      const { response } = error;
      rollbar.error("Error confirming booking", error, { booking, response });
    }
    setConfirming(false);
  };

  if (!booking) {
    return (
      <PageLayout>
        <LoadingContainer>
          {failure ? (
            <Message>
              <Icon name="add" color="danger" direction={45} />
              <span style={{ marginLeft: "1rem" }}>
                <FormattedMessage id="components.Booking.failure" />
              </span>
            </Message>
          ) : (
            <Loading text={<FormattedMessage id="loading" />} />
          )}
        </LoadingContainer>
      </PageLayout>
    );
  }

  // Final state, show success message
  if (success || (booking || {}).status === "paid") {
    return (
      <PageLayout>
        <BookingOverlay centered blackout onClose={onCancel}>
          <Success image={titleImage(booking.course)} onClose={onCancel}>
            <Heading3>
              <Icon name="checkmark" />
              {booking.course.name}
            </Heading3>
            <Paragraph>
              <strong>{booking.event.name}</strong>
            </Paragraph>
          </Success>
        </BookingOverlay>
      </PageLayout>
    );
  }

  if (booking.status === "prepared") {
    return (
      <PageLayout>
        <BookingOverlay centered blackout onClose={onCancel}>
          <BookingWrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>

            {confirming && (
              <LoadingContainer>
                <Loading text={<FormattedMessage id="components.Booking.loading.confirm" />} />
              </LoadingContainer>
            )}
            <CheckoutComponent
              booking={booking}
              onSuccess={onSuccess}
              onProcessingStateChange={setProcessing}
              onCancel={onCancel}
            />
          </BookingWrapper>
        </BookingOverlay>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <LoadingContainer>
        <Message>
          <Icon name="add" color="danger" direction={45} />
          <span style={{ marginLeft: "1rem" }}>
            <FormattedMessage id="components.Booking.failure" />
          </span>
          <br />
          <CancelLink href="#" onClick={onCancel}>
            <FormattedMessage id="actions.cancelBooking" />
          </CancelLink>
        </Message>
      </LoadingContainer>
    </PageLayout>
  );
};

export default injectIntl(Checkout);
